/* IngredientButton.css */
.responsive-button-base {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 4px;
    border-radius: 8px;
    /* Default size */
    width: 120px;
    height: 120px;
    color: white;
}

/* Adjust for mobile view */
@media (max-width: 600px) {
    .responsive-button-base {
        width: 100px; /* Override width for mobile */
        height: 100px; /* Override height for mobile */
    }
}

.responsive-button-base img {
    max-width: 70%;
    max-height: 70%;
    margin-bottom: 8px;
}

.responsive-button-base-text {
    font-size: 0.75rem;
    text-align: center;
}
